import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { useMediaQuery, useTheme } from '@mui/material'
import Link from 'next/link'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import { CaretRight } from '@phosphor-icons/react'
import { useJsApiLoader } from '@react-google-maps/api'

import FindTheRightSize from '../../components/content-blocks/find-the-right-size'
import Layout from '../../components/layout'
import NotYourAverageStorage from '../../components/content-blocks/not-your-average-storage'
import { Seo } from '../../components/seo'
import {
	setActiveMetro,
	setCityOrAddress,
	setSearchValue
} from '../../slices/searchSlice'
import { HEADER, TRANSLATIONS } from '../../util/constants'
import stringUtil from '../../util/string'
import ApiClient from '../../util/apiClient'
import FacilitiesCarousel from '../../organisms/facilities-carousel'
import blueWave from '../../public/blue-wave.png'
import stufMosaic from '../../public/stuf-mosaic2.png'
import { Trans } from 'react-i18next'
import {
	getStateFacilities,
	resetGetStateFacilitiesStatus
} from '../../slices/locationSlice'

const libraries = ['geocoding', 'marker']

export default function StateUnitsNearMe({ data }) {
	const mapRef = useRef(null)
	let dispatch = useDispatch()
	const router = useRouter()
	const theme = useTheme()
	const { t } = useTranslation(TRANSLATIONS)
	const lg = useMediaQuery('(min-width: 1024px')
	const { isLoaded: isMapLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
		libraries,
		version: 'weekly'
	})

	const facilities = useSelector((state) => state.location.stateFacilities)
	const getStateFacilitiesStatus = useSelector(
		(state) => state.location.getStateFacilitiesStatus
	)
	const [selectedLocation, setSelectedLocation] = useState('')
	const [map, setMap] = useState()
	const [cities, setCities] = useState([])
	const [groupedFacilities, setGroupedFacilities] = useState({})

	const stateName = stringUtil.uppercaseFirstLetters(data.route.openGraph.title)

	useEffect(() => {
		dispatch(getStateFacilities(data.state.id))
	}, [])

	useEffect(() => {
		if (getStateFacilitiesStatus === 'FULFILLED') {
			getCities()
			dispatch(resetGetStateFacilitiesStatus())
		} else if (getStateFacilitiesStatus === 'REJECTED') {
			dispatch(resetGetStateFacilitiesStatus())
		}
	}, [getStateFacilitiesStatus])

	const getCities = () => {
		let cityList = []
		let facilitiesByCity = {}
		facilities.forEach((facility) => {
			cityList.indexOf(facility.city_name) === -1 &&
				cityList.push(facility.city_name)
			facilitiesByCity.hasOwnProperty(facility.city_name)
				? facilitiesByCity[facility.city_name].push(facility)
				: (facilitiesByCity[facility.city_name] = [facility])
		})
		setCities(cityList.sort())
		setGroupedFacilities(facilitiesByCity)
	}

	useEffect(() => {
		if (mapRef.current && map === undefined && facilities.length > 0) {
			const googleMap = new window.google.maps.Map(mapRef.current, {
				zoom: 6,
				center: {
					lat: Number(facilities[0].latitude),
					lng: Number(facilities[0].longitude)
				},
				disableDefaultUI: true,
				mapId: '9bd129d3f55fff70'
			})
			setMap(googleMap)
		}
	}, [mapRef, map, facilities])

	useEffect(() => {
		if (facilities && map) {
			facilities.forEach((facility) => {
				const parser = new DOMParser()
				const pinSvgString = `<svg
						width='48'
						height='49'
						viewBox='0 0 48 49'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M24 3.79517C19.6255 3.80013 15.4315 5.54011 12.3382 8.63339C9.24494 11.7267 7.50496 15.9206 7.5 20.2952C7.5 34.4139 22.5 45.077 23.1394 45.5233C23.3916 45.7 23.6921 45.7947 24 45.7947C24.3079 45.7947 24.6084 45.7 24.8606 45.5233C25.5 45.077 40.5 34.4139 40.5 20.2952C40.495 15.9206 38.7551 11.7267 35.6618 8.63339C32.5685 5.54011 28.3745 3.80013 24 3.79517ZM24 14.2952C25.1867 14.2952 26.3467 14.6471 27.3334 15.3063C28.3201 15.9656 29.0892 16.9027 29.5433 17.9991C29.9974 19.0954 30.1162 20.3018 29.8847 21.4657C29.6532 22.6296 29.0818 23.6987 28.2426 24.5378C27.4035 25.3769 26.3344 25.9484 25.1705 26.1799C24.0067 26.4114 22.8003 26.2926 21.7039 25.8384C20.6075 25.3843 19.6705 24.6153 19.0112 23.6286C18.3519 22.6419 18 21.4819 18 20.2952C18 18.7039 18.6321 17.1777 19.7574 16.0525C20.8826 14.9273 22.4087 14.2952 24 14.2952Z'
							fill='#1642F0'
						/>
					</svg>`

				const pinSvg = parser.parseFromString(
					pinSvgString,
					'image/svg+xml'
				).documentElement

				const marker = new window.google.maps.marker.AdvancedMarkerElement({
					map,
					position: {
						lat: Number(facility.latitude),
						lng: Number(facility.longitude)
					},
					content: pinSvg
				})
			})
		}
	}, [map, facilities])

	const selectLocation = (city) => {
		dispatch(setSearchValue(city))
		router.push('/search-available-units')
	}

	const memoizedMap = useMemo(() => {
		return <div ref={mapRef} className='h-full'></div>
	}, [facilities, isMapLoaded, mapRef, map])

	return (
		<Layout
			site={data.site}
			showFooter={true}
			headerType={HEADER.withNav}
			headerVariant={'alternate-white'}
			showBottomNav={false}
		>
			<div
				className='flex flex-col bg-brand-page-bg'
				style={{ height: '100%' }}
			>
				<Seo route={data.route} site={data.site} />
				<section>
					<div
						className='lg:p-20  flex flex-col lg:flex-row items-center justify-center bg-cover bg-no-repeat overflow-hidden'
						style={{
							backgroundImage: `url(${blueWave.src})`
						}}
					>
						<h1 className='font-semibold text-5xl lg:hidden text-white text-center my-8'>
							<Trans
								i18nKey='stuf-storage-in'
								ns='common'
								values={{ name: stateName }}
							></Trans>
						</h1>
						<div className='hidden lg:block p-12 rounded-3xl bg-white h-full w-[500px] -mr-12 z-20 border'>
							<h1 className='font-semibold text-5xl'>
								<Trans
									i18nKey='stuf-storage-in'
									ns='common'
									values={{ name: stateName }}
								></Trans>
							</h1>
							<p className='font-serif mt-6 capitalize'>
								{t('city', { ns: 'booking' })}
							</p>
							<Select
								fullWidth
								value={selectedLocation}
								displayEmpty
								placeholder={t('select-location', { ns: 'common' })}
								onChange={(e) => setSelectedLocation(e.target.value)}
							>
								<MenuItem disabled value=''>
									{t('select-location', { ns: 'common' })}
								</MenuItem>
								{cities.map((city, index) => {
									return (
										<MenuItem
											key={index}
											value={city}
											onClick={() => selectLocation(city)}
										>
											{city}
										</MenuItem>
									)
								})}
							</Select>
						</div>
						<Image
							src='https://stuf-user-content.s3.us-east-2.amazonaws.com/facilities/2-3821WAve43.webp'
							alt=''
							height={560}
							width={850}
							className='rounded-3xl mr-[-20%] lg:mr-0'
						/>
					</div>
				</section>
				<div className='w-full p-6 mb-12 bg-white lg:hidden'>
					<p className='font-serif capitalize'>
						{t('city', { ns: 'booking' })}
					</p>
					<Select
						fullWidth
						value={selectedLocation}
						displayEmpty
						placeholder={t('select-location', { ns: 'common' })}
						onChange={(e) => setSelectedLocation(e.target.value)}
					>
						<MenuItem disabled value=''>
							{t('select-location', { ns: 'common' })}
						</MenuItem>
						{cities.map((city, index) => {
							return (
								<MenuItem
									key={index}
									value={city}
									onClick={() => selectLocation(city)}
								>
									{city}
								</MenuItem>
							)
						})}
					</Select>
				</div>
				<div className='px-6 lg:px-20'>
					<Breadcrumbs
						separator={<CaretRight size={20} />}
						aria-label='breadcrumb'
						sx={{ color: theme.palette.primary['dark-grey-2'] }}
					>
						<Link legacyBehavior href='/locations'>
							<a className='underline'>{t('locations', { ns: 'common' })}</a>
						</Link>
						<Typography sx={{ textDecoration: 'underline' }}>
							{data.state.name}
						</Typography>
					</Breadcrumbs>
				</div>
				<section>
					<div className='pb-12 pt-6  px-6 lg:px-20 lg:py-12'>
						<h2 className='text-center capitalize text-brand-dark-blue text-3xl lg:text-4xl font-bold'>
							{t('discover-our-locations', { ns: 'index' })}
						</h2>
						<hr className='h-[3px] w-[50px] mt-6 mb-10 mx-auto bg-brand-orange border-0' />

						<div className='mt-6'>
							{Object.entries(groupedFacilities)
								.sort((a, b) => {
									if (a[0] < b[0]) return -1
									if (a[0] > b[0]) return 1
									return 0
								})
								.map((group, index) => {
									return (
										<FacilitiesCarousel
											key={index}
											city={group[0]}
											facilities={group[1]}
										/>
									)
								})}
						</div>
						<div className='flex justify-center mt-10'>
							<Link legacyBehavior href={'/search-available-units'}>
								<Button
									variant='outlined'
									sx={{
										borderRadius: '99px',
										textTransform: 'capitalize',
										px: 5,
										fontSize: '18px'
									}}
									onClick={() => {
										dispatch(setCityOrAddress(''))
										dispatch(setActiveMetro(null))
									}}
								>
									{t('find-storage-near-me', { ns: 'index' })}
								</Button>
							</Link>
						</div>
					</div>
				</section>
				<section>
					<div>
						<h2 className='text-center capitalize text-brand-dark-blue text-3xl lg:text-4xl font-bold'>
							{t('storage-in-your-neighborhood', { ns: 'index' })}
						</h2>
						<hr className='h-[3px] w-[50px] mt-6 mb-10 lg:mb-[72px] mx-auto bg-brand-orange border-0' />

						<div className='w-full h-[600px] lg:px-20'>
							{isMapLoaded ? memoizedMap : null}
						</div>
						<div className='flex justify-center mt-10'>
							<Link legacyBehavior href={'/search-available-units'}>
								<Button
									variant='outlined'
									sx={{
										borderRadius: '99px',
										textTransform: 'capitalize',
										px: 5,
										fontSize: '18px'
									}}
									onClick={() => {
										dispatch(setCityOrAddress(''))
										dispatch(setActiveMetro(null))
									}}
								>
									{t('search-storage-units-near-me', { ns: 'common' })}
								</Button>
							</Link>
						</div>
					</div>
				</section>
				<section>
					<div className='flex flex-col-reverse items-center md:flex-row bg-brand-dark-blue lg:bg-transparent my-20'>
						<div className='w-full pb-12 pt-0 px-6 lg:py-20 lg:pr-0 lg:pl-20 xl:pl-40 relative overflow-hidden bg-brand-dark-blue lg:rounded-r-full lg:flex flex-col justify-center '>
							<div className='lg:max-w-lg'>
								<h2 className='text-white font-bold text-3xl lg:text-4xl text-center lg:text-left hidden lg:block'>
									{t('average-price-of-storage-units', {
										ns: 'common',
										state: stateName
									})}
								</h2>
								<hr className='h-[3px] w-[50px] mt-6 mb-10 bg-brand-orange border-0 mx-auto lg:ml-0 hidden lg:block' />
								<Image
									src={stufMosaic}
									alt=''
									layout='responsive'
									sizes='100vw'
									width={425}
									height={300}
								/>
							</div>
						</div>
						<div className='w-full md:w-1/2 px-6 py-10 lg:p-0 bg-brand-dark-blue lg:bg-transparent lg:mr-20 lg:ml-[-25%] z-30'>
							<h2 className='text-white font-bold text-3xl text-center lg:hidden '>
								{t('average-price-of-storage-units', {
									ns: 'common',
									state: stateName
								})}
							</h2>
							<hr className='h-[3px] w-[50px] mt-6 mb-10 bg-brand-orange border-0 mx-auto lg:hidden' />
							<div className='border rounded-xl bg-white p-6 lg:p-10 grid grid-cols-2 gap-3 shadow'>
								<h4 className='font-semibold text-lg lg:text-xl capitalize'>
									{t('unit-size', { ns: 'common' })}
								</h4>
								<h4 className='font-semibold text-lg lg:text-xl capitalize'>
									{t('stuf-average-price', { ns: 'common' })}
								</h4>
								<p className='font-serif'>Small (3 x 3)</p>
								<p className='font-serif'>
									$40<span className='text-brand-graphite'>/mo</span>
								</p>
								<p className='font-serif'>Small (3 x 4)</p>
								<p className='font-serif'>
									$45<span className='text-brand-graphite'>/mo</span>
								</p>
								<p className='font-serif'>Small (4 x 4)</p>
								<p className='font-serif'>
									$60<span className='text-brand-graphite'>/mo</span>
								</p>
								<p className='font-serif'>Medium (4 x 5)</p>
								<p className='font-serif'>
									$65<span className='text-brand-graphite'>/mo</span>
								</p>
								<p className='font-serif'>Medium (5 x 5)</p>
								<p className='font-serif'>
									$75<span className='text-brand-graphite'>/mo</span>
								</p>
								<p className='font-serif'>Large (6 x 5)</p>
								<p className='font-serif'>
									$85<span className='text-brand-graphite'>/mo</span>
								</p>
								<p className='font-serif'>Large (6 x 6)</p>
								<p className='font-serif'>
									$90<span className='text-brand-graphite'>/mo</span>
								</p>
							</div>
						</div>
					</div>
				</section>
				<section>
					<NotYourAverageStorage />
				</section>
				<section>
					<FindTheRightSize />
				</section>
			</div>
		</Layout>
	)
}

export async function getStaticPaths() {
	const apiClient = new ApiClient()
	let states
	try {
		states = await apiClient.get('/state/active')
	} catch (err) {
		throw new Error(
			`GET /state/active API Failed to get states: ${err.message}`
		)
	}

	const paths = states.map((state) => ({
		params: {
			stateUrl: `${stringUtil.formatStringForUrl(state.name)}-units-near-me`
		}
	}))

	return {
		paths,
		fallback: true
	}
}

export async function getStaticProps({ params, locale }) {
	const apiClient = new ApiClient()
	// Remove the "-units-near-me" part of the string
	const stateNameDashes = params.stateUrl.slice(0, -14)

	let states = []
	try {
		states = await apiClient.get('/state/active')
	} catch (err) {
		throw new Error(
			`GET /state/active API Failed to get states: ${err.message}`
		)
	}

	if (states.length === 0) {
		return {
			redirect: {
				destination: '/locations',
				permanent: false
			}
		}
	}

	const state = states.find(
		(state) => stateNameDashes === stringUtil.formatStringForUrl(state.name)
	)
	return {
		props: {
			...(await serverSideTranslations(locale, TRANSLATIONS)),
			data: {
				state,
				route: {
					openGraph: {
						title: state.name,
						description: `Stuf Storage | ${state.name}`
					}
				},
				site: {
					openGraph: {
						description: ''
					}
				}
			}
		}
	}
}
