import Button from '@mui/material/Button'
import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import FacilityCardSearch from '../molecules/facility-card-search'
import { TRANSLATIONS } from '../util/constants'

const FacilitiesCarousel = ({ city, facilities }) => {
	const { t } = useTranslation(TRANSLATIONS)
	const lg = useMediaQuery('(min-width: 1024px')
	const [isOpen, setIsOpen] = useState(false)
	return (
		<div>
			<div className='lg:flex justify-between mb-4 hidden'>
				<div className='text-3xl text-brand-dark-blue font-semibold'>
					{city}
				</div>
				{facilities.length > 2 && (
					<div className='flex gap-3'>
						<Button
							onClick={() => setIsOpen(!isOpen)}
							sx={{ fontSize: '18px', px: '40px' }}
						>
							{isOpen
								? t('show-less', { ns: 'common' })
								: t('view-all', { ns: 'common' })}
						</Button>
						<div
							className={`swiper-prev border rounded-full p-2 cursor-pointer ${
								isOpen && 'opacity-30 cursor-auto pointer-events-none'
							}`}
							style={{ position: 'relative' }}
						>
							<CaretLeft size={24} weight='thin' />
						</div>
						<div
							className={`swiper-next border rounded-full p-2 cursor-pointer ${
								isOpen && 'opacity-30 cursor-auto pointer-events-none'
							}`}
							style={{ position: 'relative' }}
						>
							<CaretRight size={24} weight='thin' />
						</div>
					</div>
				)}
			</div>

			{lg && facilities.length > 2 && !isOpen ? (
				<Swiper
					slidesPerView={2}
					spaceBetween={24}
					modules={[Navigation]}
					navigation={{
						prevEl: '.swiper-prev',
						nextEl: '.swiper-next',
						disabledClass: 'opacity-30 cursor-auto pointer-events-none'
					}}
				>
					{facilities.map((facility, index) => (
						<SwiperSlide key={index}>
							<FacilityCardSearch facility={facility} />
						</SwiperSlide>
					))}
				</Swiper>
			) : (
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
					{facilities.map((facility, index) => (
						<FacilityCardSearch key={index} facility={facility} />
					))}
				</div>
			)}
		</div>
	)
}

export default FacilitiesCarousel
